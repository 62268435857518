import '@nlux/themes/nova.css';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { AiChat } from '@nlux/react';
import { useChatAdapter } from '@nlux/langchain-react';

interface IProps {
  baseUrl: string;
  user: any;
  token: string;
  setLayersActive: (any) => void;
  setLocationHighlight: (any) => void;
  setMapBounds: (any) => void;
}

const IOChat = (props: IProps) => {
  const { baseUrl, user, token } = props;

  const authHeaders = useMemo(
    () => ({
      Authorization: `Bearer ${token}`,
    }),
    [token]
  );

  const [conversationHistory, setConversationHistory] = useState([]);
  const [isLoaded, setLoaded] = useState(false);

  useEffect(() => {
    async function getChatHistory() {
      if (token) {
        const response = await fetch(`${baseUrl}/chat_history/`, {
          headers: authHeaders,
        });
        return await response.json();
      }
    }
    getChatHistory().then(data => {
      if (Array.isArray(data)) {
        // drop first message
        data.shift();
        // convert from langserve back to nlux
        const conversationHistory = data.map(entry => {
          return {
            message: entry.content,
            role: entry.type === 'human' ? 'user' : 'assistant',
          };
        });
        setConversationHistory(conversationHistory);
        setLoaded(true);
      } else {
        console.error('getChatHistory data', data);
      }
    });
  }, [baseUrl, authHeaders, token]);

  const inputPreProcessor = useCallback((input, conversationHistory) => {
    // rename conversationHistory to how langserve expects chat_history
    return {
      input: input,
      chat_history: (conversationHistory || []).map(entry => {
        return {
          content: entry.message,
          type: entry.role === 'user' ? 'human' : 'ai',
        };
      }),
    };
  }, []);

  const outputPreProcessor = useCallback(output => {
    return output.output;
  }, []);

  const adapter = useChatAdapter({
    url: `${baseUrl}/bob-auth`,
    headers: authHeaders,
    inputPreProcessor: inputPreProcessor,
    outputPreProcessor: outputPreProcessor,
  });

  const messageReceivedCallback = useCallback(message => {
    console.log(message);
  }, []);

  if (!isLoaded) {
    return <div>Loading</div>;
  }

  return (
    <AiChat
      adapter={adapter}
      className="ioChat"
      events={{
        messageReceived: messageReceivedCallback,
      }}
      personaOptions={{
        assistant: {
          name: 'Bob',
          avatar: '/static/bob_avatar.png',
          tagline: 'Your AI Geospatial Sidekick',
        },
        user: {
          name: user.name || 'User',
        },
      }}
      messageOptions={{
        markdownLinkTarget: 'self',
      }}
      initialConversation={conversationHistory}
      promptBoxOptions={{
        autoFocus: true,
        placeholder: 'type your question here',
      }}
      displayOptions={{
        height: '100%',
        width: '100%',
      }}
    />
  );
};
export default IOChat;
